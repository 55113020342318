<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <b-alert show variant="light" class="alert alert-elevate">
          <div class="alert-icon">
            <i class="flaticon-warning kt-font-brand"></i>
          </div>
          <div class="alert-text mt-3">
            <p>{{ $t("STUDENT_FEEDBACKS.GUIDE") }}</p>
            <p>{{ $t("STUDENT_FEEDBACKS.NOTE") }}</p>
          </div>
        </b-alert>
        <KTPortlet :title="title">
          <template v-slot:body>
            <b-alert
              :show="dismissCountDown"
              dismissible
              variant="success"
              @dismissed="dismissCountDown = 0"
              @dismiss-count-down="countDownChanged"
            >
              <p>
                {{ $t("STUDENT_FEEDBACKS.SUCCESS_MESSAGE") }}
                {{ dismissCountDown }}
              </p>
              <b-progress
                variant="warning"
                :max="dismissSecs"
                :value="dismissCountDown"
                height="10px"
              ></b-progress>
            </b-alert>
            <div>
              <b-form class="kt-form" @submit.stop.prevent="onSubmit">
                <div
                  role="alert"
                  v-if="errors.length"
                  v-bind:class="{ show: errors.length }"
                  class="alert fade alert-danger"
                >
                  <div class="alert-text">
                    <ul v-for="(error, i) in errors" :key="i">
                      <li>{{ error }}</li>
                    </ul>
                  </div>
                </div>
                <b-overlay
                  :show="form.submitting"
                  variant="transparent"
                  rounded="sm"
                >
                  <b-row>
                    <b-col md="12">
                      <div role="group">
                        <b-form-group
                          :label="$t('STUDENT_FEEDBACKS.FEEDBACK_TYPE') + ':'"
                        >
                          <b-form-radio-group
                            id="feedback_type"
                            name="feedback_type"
                            v-model="$v.form.feedback_type.$model"
                            :options="feedback_options"
                            :state="validateState('feedback_type')"
                            class="mb-3"
                            value-field="item"
                            text-field="name"
                            buttons
                            button-variant="outline-primary"
                            size="md"
                          ></b-form-radio-group>
                          <b-form-invalid-feedback id="input-2-live-feedback">
                            {{
                              $t("VALIDATION.REQUIRED", {
                                name: $t("STUDENT_FEEDBACKS.FEEDBACK_TYPE")
                              })
                            }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <div role="group">
                        <label>{{ $t("GENERAL.TITLE") }}</label
                        >:
                        <b-form-input
                          id="title"
                          name="title"
                          v-model="$v.form.title.$model"
                          :state="validateState('title')"
                          :placeholder="
                            $t('GENERAL.TITLE') + ' ' + $t('GENERAL.INSERT')
                          "
                          autocomplete="off"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-2-live-feedback">
                          {{
                            $t("VALIDATION.REQUIRED", {
                              name: $t("GENERAL.TITLE")
                            })
                          }}
                        </b-form-invalid-feedback>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row class="mt-3">
                    <b-col md="12">
                      <div role="group">
                        <label>{{ $t("GENERAL.DESCRIPTION") }}</label
                        >:
                        <b-form-textarea
                          id="description"
                          name="description"
                          v-model="$v.form.description.$model"
                          :state="validateState('description')"
                          :placeholder="
                            $t('GENERAL.DESCRIPTION') +
                              ' ' +
                              $t('GENERAL.INSERT')
                          "
                          rows="8"
                        ></b-form-textarea>
                        <b-form-invalid-feedback id="input-2-live-feedback">
                          {{
                            $t("VALIDATION.REQUIRED", {
                              name: $t("GENERAL.DESCRIPTION")
                            })
                          }}
                        </b-form-invalid-feedback>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row class="mt-4">
                    <b-col md="12">
                      <div role="group">
                        <b-form-checkbox
                          id="is_identity_hidden"
                          name="is_identity_hidden"
                          v-model="is_identity_hidden"
                          value="1"
                          unchecked-value="0"
                          size="lg"
                        >
                          {{ $t("GENERAL.IS_IDENTITY_HIDDEN") }}
                        </b-form-checkbox>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row class="mt-4">
                    <b-col class="kt-margin-t-5">
                      <b-button
                        type="submit"
                        id="kt_submit"
                        class="btn btn-primary btn-elevate kt-login__btn-primary"
                      >
                        <i class="fas fa-save"></i>
                        {{ $t("GENERAL.SUBMIT") }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-overlay>
              </b-form>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import ApiService from "@/common/api.service";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "studentFeedbacks",
  data() {
    return {
      title: this.$t("STUDENT_FEEDBACKS.TITLE"),
      errors: [],
      form: {
        feedback_type: null,
        title: null,
        description: null,
        submitting: false
      },
      is_identity_hidden: 0,
      dismissSecs: 10,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      feedback_options: [
        { item: 1, name: this.$t("STUDENT_FEEDBACKS.COMPLAINTS") },
        {
          text: 0,
          name: this.$t("STUDENT_FEEDBACKS.OR"),
          disabled: true,
          variant: "outline-dark"
        },
        { item: 2, name: this.$t("STUDENT_FEEDBACKS.SUGGESTIONS") }
      ]
    };
  },
  components: {
    KTPortlet
  },
  validations: {
    form: {
      feedback_type: {
        required
      },
      title: {
        required
      },
      description: {
        required
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit(event) {
      this.errors = [];
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      let formData = {
        type: this.$v.form.feedback_type.$model,
        title: this.$v.form.title.$model,
        description: this.$v.form.description.$model,
        is_identity_hidden: this.is_identity_hidden
      };
      this.form.submitting = true;
      ApiService.put("student/student-feedbacks", formData)
        .then(() => {
          this.form.submitting = false;
          this.is_identity_hidden = 0;
          this.$v.form.feedback_type.$model = null;
          event.target.reset();
          this.showDismissibleAlert = true;
          this.showAlert();
        })
        .catch(({ response }) => {
          this.form.submitting = false;
          this.errors = response.data.errors;
        });
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("MENU.STUDENT_FEEDBACKS"),
        route: this.$route
      },
      { title: "" }
    ]);
  }
};
</script>
